require('./bootstrap');

const flatpickr = require("flatpickr");

window.data_get = function(obj, str) {
    let props = str.split('.'),
    	result = obj;
    for(let prop of props) result = result[prop];
    return result;
}

window.Template = class {
	constructor(template_id){
		this.template_id = template_id;
		this.tpl = document.getElementById(template_id).innerHTML;
	}

	fill(data){
		let matches = this.tpl.matchAll(/\[\[([^\[\]]*?)\]\]/g),
			filled = this.tpl;

		for(let match of matches){
			let prop = match[1];
			let val = data_get(data, prop);
				val = val != null && val != undefined ? val : '';
			filled = filled.replaceAll(`[[${prop}]]`, `${val}`);
		}

		return filled;
	}
}
